<template>
  <!-- Arts -->
  <div class="bg_gradient">
    <section class="section_arts">
      <div class="container">
        <div class="text-center mb-5 wow fadeInUp">
          <h2 class="h2">
            ARTs
          </h2>
          <p>
            Contrary to conventional NFT projects, which are randomly generated
            with different characteristics, Atlantis-P invited more than 100
            famous artists to create based on the template of the 4 main
            characters in the Atlantis Kingdom, Each NFT is a unique work of art
            dedicated by a true artist.
          </p>
        </div>
        <div class="row gx-2 gx-sm-3 gx-lg-4 mb-5 wow fadeInUp">
          <!-- <div class="col-lg-3 col-md-4 col-6 mb-3 mb-lg-4">
            <div class="art_item">
              <div class="image" style="background-image: url('img/arts/01.jpg');">
                <div class="cover">
                  <a href="#" class="btn btn-primary mb-3">MINT</a>
                  <a href="#" class="btn btn-outline-light">VIEW</a>
                </div>
              </div>
              <div class="info">
                <div class="name">Artist name</div>
                <div class="title">大威德明王</div>
              </div>
            </div>
          </div> -->
          <div
            v-for="item in artList"
            :key="item.id"
            class="col-lg-3 col-md-4 col-6 mb-2 gx-sm-3 mb-lg-4"
          >
            <div class="art_item">
              <div
                class="image"
                :style="{ backgroundImage: 'url(' + item.img + ')' }"
              >
                <div class="cover">
                  <a
                    id="item.id"
                    href="#"
                    class="btn btn-primary mb-3"
                  >MINT</a>
                  <a
                    href="detail"
                    class="btn btn-outline-light"
                  >VIEW</a>
                </div>
              </div>
              <div class="info">
                <div class="name">
                  {{ item.name }}
                </div>
                <div class="title">
                  {{ item.title }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'Art',
  data() {
    return {
      artList: [
        {
          id: '01',
          img: 'img/arts/01.jpg',
          name: 'Zoe',
          title: '大威德明王',
        },
        {
          id: '02',
          img: 'img/arts/02.jpg',
          name: 'hiro',
          title: '機器人的反擊',
        },
        {
          id: '03',
          img: 'img/arts/03.jpg',
          name: 'brian',
          title: '好大一隻魚',
        },
        {
          id: '04',
          img: 'img/arts/04.jpg',
          name: 'Emma',
          title: '生氣囉要生氣囉',
        },
        {
          id: '01',
          img: 'img/arts/01.jpg',
          name: 'Zoe',
          title: '大威德明王',
        },
        {
          id: '02',
          img: 'img/arts/02.jpg',
          name: 'hiro',
          title: '機器人的反擊',
        },
        {
          id: '03',
          img: 'img/arts/03.jpg',
          name: 'brian',
          title: '好大一隻魚',
        },
        {
          id: '04',
          img: 'img/arts/04.jpg',
          name: 'Emma',
          title: '生氣囉要生氣囉',
        },
      ],
    }
  },
}
</script>

<style scoped></style>
